import React from "react"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

import perk1Img from "../images/speed.svg"
import perk2Img from "../images/piggy-bank.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

const HomePage = () => {
  return (
    <>
      <Banner />
      <TextBlock
        id="about"
        title="Infrastructure As Code"
        paragraph="As infrastructure grows so does the complexity.  JMAC Security removes the complexities by giving fullstack development teams the ability
        to quickly and efficiently execute projects, plan and test infrastructure changes, and make deployments repeatable and predictable."
      >
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Learn More!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="perks"
        title="Get your product to market quicker with Jmac Security"
        subtitle="Our consultants are cloud experts, delivering business value in a quick iterate fashion.">
        <div className="flex-container trio-block">
          <Perk
            img={perk1Img}
            alt="Terraform Assement"
            title="Terraform Assement"
            content="Our consultants will assess your architectural needs, provide a cloud agnostic roadmap to improve your current infrastructure."
          />
          <Perk
            img={perk2Img}
            alt="Terraform Development"
            title="Terraform Development"
            content="Our consultants can help with migrating from AWS Cloudformation to Terraform, intergrating new Terraform providers, and designing multi-cloud development environments."
          />
          <Perk
            img={perk3Img}
            alt="Terraform Implementation"
            title="Terraform Implementation"
            content="Our consultants can implement cloud services, CI/CD, cluster monitoring and cloud management."
          />
        </div>
      </TextBlockImg>
      <Packages
        title="Our Packages"
        para="Choose the perfect solution for you. With benefits to suit all budgets JMAC Security can offer amazing value and expert advice."
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Terrform Assements">
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Terraform single cloud infrastructure" active={true}>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Terraform Professional Support and Management">
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Contact JMAC Security today and see how we can help automate your development cycle"
        subtitle=""
      />
    </>
  )
}

export default HomePage